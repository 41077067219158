import filter from 'lodash/filter'
import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import toPairs from 'lodash/toPairs'
import mapValues from 'lodash/mapValues'
import sortBy from 'lodash/sortBy'
import fromPairs from 'lodash/fromPairs'
import isEmpty from 'lodash/isEmpty'
import * as React from 'react'
import { useMemo, useState, Fragment } from 'react'
import useLdClient from './useLdClient'
import { TitleBar, StaffTable, Json, Searchbox } from '../../components'
import { Flags } from './types'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MenuIcon } from '@toasttab/buffet-pui-icons'
import { stringMatches } from '../../helpers'
import useFeatureFlagOverrides from './useFeatureFlagOverrides'
import FeatureBadge from './FeatureBadge'

const parseFlags = (flags: Flags) => {
  const pairs = toPairs(flags)

  const groupPairs = toPairs(
    groupBy(pairs, ([key]) => {
      const [first, second] = key.split('-')
      return first === 'ec' ? `${first}-${second}` : first
    })
  )

  const sortedPairs = sortBy(groupPairs, ([key]) => {
    return key.startsWith('ec-') ? `1_${key}` : `2_${key}`
  })

  return fromPairs(sortedPairs)
}

const FeatureFlagsPage = () => {
  const client = useLdClient()

  const {
    overrides, //
    onSetOverride,
    onClearOverrides
  } = useFeatureFlagOverrides()

  // @ts-ignore
  window.client = client

  const value = useMemo(() => {
    const rawFlags = client?.allFlags?.() as Flags
    return {
      flags: rawFlags,
      parsedGroups: parseFlags(rawFlags),
      ldUser: client?.getUser()
    }
  }, [client])

  const { ldUser, parsedGroups } = value

  const [search, setSearch] = useState<string>('')

  const filteredGroups = mapValues(parsedGroups, (val) => {
    return filter(val, ([key]) => stringMatches(key, search))
  })

  const renderedGroups = map(filteredGroups, (group, key) => {
    if (isEmpty(group)) return null

    return (
      <Fragment key={key}>
        <StaffTable.RowHeading label={key} />
        {map(group, ([ffKey, value]) => {
          const override = overrides[ffKey]
          return (
            <StaffTable.Row key={ffKey}>
              {ffKey}
              <FeatureBadge enabled={value} />
              <FeatureBadge enabled={override} />

              <MenuDropdown
                showArrow
                renderToggle={(props) => {
                  return <IconButton {...props} icon={<MenuIcon />} />
                }}
              >
                <ListItem
                  onClick={() => onSetOverride(ffKey, true)}
                  label='Enable'
                  placeholder='Enable'
                />
                <ListItem
                  onClick={() => onSetOverride(ffKey, false)}
                  label='Disable'
                  placeholder='Disable'
                />
                <ListItem
                  onClick={() => onSetOverride(ffKey, null)}
                  label='Clear'
                  placeholder='Clear'
                />
              </MenuDropdown>
            </StaffTable.Row>
          )
        })}
      </Fragment>
    )
  })

  return (
    <>
      <TitleBar title='Feature Flags' />

      <h3>LaunchDarkly User</h3>
      <CardContainer>
        <Json json={ldUser} />
      </CardContainer>

      <div className='pb-8' />

      <h3>Feature Flags</h3>

      <Searchbox
        value={search}
        onChange={setSearch}
        placeholder='search flags'
      />

      <div className='pb-8' />

      <CardContainer>
        <StaffTable columns={['Flag', 'FF', 'Override', 'Actions']}>
          {renderedGroups}
        </StaffTable>
      </CardContainer>
    </>
  )
}

export default FeatureFlagsPage
