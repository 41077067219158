import * as React from 'react'
import { useState } from 'react'
import { TitleBar, ObjectCards } from '../../components'
import { Button } from '@toasttab/buffet-pui-buttons'
import random from 'lodash/random'
import range from 'lodash/range'
import map from 'lodash/map'
import { getSize, clearKeys, makeFakeData } from '../../helpers/storage'

const MIN = 1000000

const ARRAY_SIZE = 121

const LocalStoragePage = () => {
  // @ts-ignore
  const storage = { ...window.localStorage }

  const [info, setInfo] = useState(() => getSize('local'))

  const onCheckSize = () => setInfo(getSize('local'))

  const onAddLocalStorageData = () => {
    const key = `test-ls-value-${random(MIN, MIN * 10)}`
    const data = makeFakeData(ARRAY_SIZE)

    console.log('data to add', { data })
    try {
      localStorage.setItem(key, JSON.stringify(data))
      console.log(`added data to '${key}'`)
    } catch (error) {
      console.error(`unable to add data to '${key}'`, error)
    }
    onCheckSize()
  }

  const onClearTestData = () => {
    clearKeys('local', 'test-ls-value-')
    onCheckSize()
  }

  return (
    <>
      <TitleBar
        title={`Local Storage (${info.sizeKb})`}
        actions={
          <span className='flex flex-row gap-2'>
            <Button variant='link' onClick={onAddLocalStorageData}>
              Add ~1mb of Data
            </Button>
            <Button variant='link' onClick={onCheckSize}>
              Check Size
            </Button>
            <Button variant='link' onClick={onClearTestData}>
              Clear Test Data
            </Button>
          </span>
        }
      />
      <ObjectCards object={storage} />
    </>
  )
}

export default LocalStoragePage
