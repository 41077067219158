import * as React from 'react'

type Props = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  actions?: React.ReactNode
  children?: React.ReactNode
}

const TitleBar = (props: Props) => {
  const { title, subtitle, actions, children } = props

  return (
    <div className='pt-10 pb-6'>
      <div className='flex flex-row justify-between items-center'>
        <div className='pb-4'>
          <div className='type-headline-2'>{title}</div>
          {subtitle && <div className='type-headline-5 pt-4'>{subtitle}</div>}
        </div>

        {actions && <div>{actions}</div>}
      </div>

      {children && <div className='pt-6'>{children}</div>}
    </div>
  )
}

export default TitleBar
