import * as React from 'react'
import { useMemo } from 'react'
import { Json } from '../../components'

import { CardContainer } from '@toasttab/buffet-pui-card'

type Props = {
  title: any
  value: any
}

const Section = ({ title, value }: Props) => {
  const json = useMemo(() => {
    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  }, [value])

  return (
    <>
      <CardContainer>
        <h3>{title}</h3>
        <div className='pb-4' />
        <Json json={json} />
      </CardContainer>
      <div className='pb-8' />
    </>
  )
}

export default Section
