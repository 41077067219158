import * as React from 'react'
import { useState } from 'react'
import { TitleBar, ObjectCards } from '../../components'
import { Button } from '@toasttab/buffet-pui-buttons'
import random from 'lodash/random'
import { getSize, clearKeys, makeFakeData } from '../../helpers/storage'

const MIN = 1000000

const ARRAY_SIZE = 121

const SessionStoragePage = () => {
  // @ts-ignore
  const storage = { ...window.sessionStorage }

  const [info, setInfo] = useState(() => getSize('session'))

  const onCheckSize = () => setInfo(getSize('session'))

  const onAddSessionStorageData = () => {
    const key = `test-session-storage-value-${random(MIN, MIN * 10)}`
    const data = makeFakeData(ARRAY_SIZE)

    console.log('data to add', { data })
    try {
      sessionStorage.setItem(key, JSON.stringify(data))
      console.log(`added data to '${key}'`)
    } catch (error) {
      console.error(`unable to add data to '${key}'`, error)
    }
    onCheckSize()
  }

  const onClearTestData = () => {
    clearKeys('session', 'test-session-storage-value-')
    onCheckSize()
  }

  return (
    <>
      <TitleBar
        title={`Session Storage (${info.sizeKb})`}
        actions={
          <span className='flex flex-row gap-2'>
            <Button variant='link' onClick={onAddSessionStorageData}>
              Add ~1mb of Data
            </Button>
            <Button variant='link' onClick={onCheckSize}>
              Check Size
            </Button>
            <Button variant='link' onClick={onClearTestData}>
              Clear Test Data
            </Button>
          </span>
        }
      />
      <ObjectCards object={storage} />
    </>
  )
}

export default SessionStoragePage
