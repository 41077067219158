import * as React from 'react'
import { useState, useMemo } from 'react'
import { Split } from '../../components'
import Text from './Text'

const downloadCSV = (str: string) => {
  const csvData = new Blob([str], { type: 'text/csv;charset=utf-8;' })

  const url = window.URL.createObjectURL(csvData)

  var tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', 'download.csv')
  tempLink.click()
}

const DEFAULT_TRANSFORMER = (str: string) => str

type Props = {
  transformer: (str: string) => string
}

const Transformer = (props: Props) => {
  const { transformer = DEFAULT_TRANSFORMER } = props

  const [source, setSource] = useState<string>('')

  const output = useMemo(() => {
    try {
      return transformer(source)
    } catch (e) {
      return `${e}`
    }
  }, [source, transformer])

  const onDownload = () => {
    downloadCSV(output)
  }

  return (
    <>
      <Text value={source} onChange={setSource} placeholder='Source' />
      <div className='h-12' />
      <Text value={output} placeholder='Output' />
      <button onClick={onDownload}>Download CSV</button>
    </>
  )
}

export default Transformer
