import sum from 'lodash/sum'
import map from 'lodash/map'
import random from 'lodash/random'
import range from 'lodash/range'

const toKb = (size: number) => {
  return (
    (size / 1024).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) + ' KB'
  )
}

type Item = {
  key: string
  size: number
  sizeKb: string
}

type Kind = 'local' | 'session'

const getSize = (kind: Kind) => {
  const storage =
    kind === 'local'
      ? localStorage
      : kind === 'session'
      ? sessionStorage
      : undefined

  if (!storage) {
    throw new Error('invalid storage type')
  }

  const items: Item[] = []

  for (const key in storage) {
    if (storage.hasOwnProperty(key)) {
      const size = (storage[key].length + key.length) * 2

      items.push({ key, size, sizeKb: toKb(size) })
    }
  }
  const total = sum(map(items, (item) => item.size))

  return {
    items,
    size: total,
    sizeKb: toKb(total)
  }
}

const clearKeys = (kind: Kind, substring: string) => {
  const storage =
    kind === 'local'
      ? localStorage
      : kind === 'session'
      ? sessionStorage
      : undefined

  if (!storage) {
    throw new Error('invalid storage type')
  }

  for (const key in storage) {
    const isClearable =
      storage.hasOwnProperty(key) && //
      substring &&
      key.includes(substring)

    if (isClearable) storage.removeItem(key)
  }
}

const makeFakeData = (dimension: number) => {
  const MIN = 1000000

  const list = range(0, dimension)
  return map(list, (i) => {
    return {
      label: `Item ${i}`,
      children: map(list, (j) => {
        return {
          label: `Child ${j}`,
          value: random(MIN, MIN * 10)
        }
      })
    }
  })
}

export { getSize, clearKeys, makeFakeData }
