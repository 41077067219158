import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import * as Yup from 'yup'
import { Formik, useFormikContext, Form } from 'formik'

import {
  CardSelectorGroupField,
  SubmitButton,
  TextInputField
} from '@toasttab/buffet-pui-forms'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Alert } from '@toasttab/buffet-pui-alerts'

interface AccessControlForm {
  email?: string
  action: 'grant' | 'revoke'
}

const AccessControlFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
})

export function AccessControl() {
  const { mutateAsync, isError, isSuccess, reset, failureReason } = useMutation<
    void,
    { message: string },
    AccessControlForm
  >({
    mutationFn: (values: AccessControlForm) =>
      fetch(
        `/user/permissions/site/admin${
          values.action === 'revoke' ? '/revoke' : ''
        }`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(values)
        }
      )
        .then((response) => response.json())
        .then(async (response) => {
          if (response.status === 404) {
            throw new Error('not found')
          }
          if (response.status === 403) {
            throw new Error(response.message)
          }
        })
  })

  return (
    <div className='space-y-8'>
      <h2>Manage access control</h2>
      <CardContainer>
        <Formik<AccessControlForm>
          initialValues={{ email: undefined, action: 'grant' }}
          onSubmit={(values) => mutateAsync(values)}
          validationSchema={AccessControlFormSchema}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className='grid gap-6'>
                {isError && failureReason !== null && (
                  <Alert variant='error' onDismiss={reset} className='w-full'>
                    {failureReason.message}
                  </Alert>
                )}
                {isSuccess && (
                  <Alert variant='success' onDismiss={reset} className='w-full'>
                    Was able to successfully {values.action} access!
                  </Alert>
                )}
                <TextInputField
                  type='email'
                  name='email'
                  autoComplete='off'
                  label='User email'
                  required
                />
                <CardSelectorGroupField
                  itemsContainerClassName='grid-cols-2'
                  label='Action'
                  name='action'
                  required
                  options={[
                    { value: 'grant', contents: 'Grant' },
                    { value: 'revoke', contents: 'Revoke' }
                  ]}
                />

                <Message />

                <SubmitButton
                  isInProgress={isSubmitting}
                  className='capitalize justify-self-end'
                >
                  {values.action} access
                </SubmitButton>
              </div>
            </Form>
          )}
        </Formik>
      </CardContainer>
    </div>
  )
}

const Message = () => {
  const form = useFormikContext<AccessControlForm>()

  if (form.isValid && form.dirty) {
    return (
      <span className='type-large'>
        You are about to{' '}
        <span className='font-semibold'>{form.values.action}</span> "Site Admin"
        access for <span className='font-semibold'>{form.values.email}</span>{' '}
      </span>
    )
  }

  return null
}
