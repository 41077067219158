import * as React from 'react'
import { TitleBar, ObjectCards } from '../../components'
import { useBanquetProps } from 'banquet-runtime-modules'

const EcBanquetPropsPage = () => {
  const props = useBanquetProps()

  // @ts-ignore
  const { ec } = props

  return (
    <>
      <TitleBar title='EC Banquet Props' />

      <ObjectCards object={ec} />
    </>
  )
}

export default EcBanquetPropsPage
