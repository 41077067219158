import omit from 'lodash/omit'
import { useEcLocalStorageState } from '@toasttab/ec-storage'

const useFeatureFlagOverrides = () => {
  const [overrides, setOverrides] = useEcLocalStorageState(
    'feature-flag-local-overrides'
  )

  const onSetOverride = (key: string, value: boolean | null) => {
    setOverrides((overrides) => {
      if (value === null) {
        return omit(overrides, key)
      }
      return { ...overrides, [key]: value }
    })
  }

  const onClearOverrides = () => setOverrides({})

  return { overrides, onSetOverride, onClearOverrides }
}

export default useFeatureFlagOverrides
