import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { domElementGetter } from '@toasttab/ec-layout'
import App from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-ec-staff-tools',
  rootComponent: App,
  domElementGetter: domElementGetter.application,
  portalContainers: ['banquetPortalsContainer'],
  sentry: {
    publicKey: '2ac21c87753443fe96a1ef139b48d1a3@o37442',
    projectId: '1866872',
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-staff-tools-spa'
