import * as React from 'react'
import { TitleBar } from '../../components'
import { Link, Navigate, useRoutes } from 'react-router-dom'
import { Translations, ExportImport } from './transformers'

const routes = [
  {
    path: 'translations',
    element: <Translations />
  },
  {
    path: 'export-import',
    element: <ExportImport />
  },
  {
    path: '*',
    element: <Navigate to='translations' />
  }
]

const ExperimentsPage = () => {
  const element = useRoutes(routes)
  return (
    <>
      <TitleBar title='Data Processing' />

      <div>
        <Link className='inline-block p-1 m-1' to='translations'>
          Translations
        </Link>
        <Link className='inline-block p-1 m-1' to='export-import'>
          Export/Import
        </Link>
      </div>

      <div>{element}</div>
    </>
  )
}

export default ExperimentsPage
