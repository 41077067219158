import * as React from 'react'
import { useRef } from 'react'
import { SearchInput } from '@toasttab/buffet-pui-text-input'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CancelIcon } from '@toasttab/buffet-pui-icons'

type Props = {
  value: string
  onChange: (str: string) => void
  placeholder?: string
}

const Searchbox = (props: Props) => {
  const { value, onChange, placeholder } = props

  const ref = useRef<HTMLInputElement>(null)

  return (
    <SearchInput
      value={value}
      ref={ref}
      onChange={(e) => {
        onChange(e.target.value)
      }}
      placeholder={placeholder}
      suffix={
        value && (
          <IconButton
            icon={<CancelIcon />}
            textClassName='text-gray-50 hover:text-gray-75 focus:text-gray-75'
            contained
            onClick={() => {
              onChange('')
              ref.current?.focus()
            }}
          />
        )
      }
      suffixVariant='iconButton'
    />
  )
}
export default Searchbox
