import * as React from 'react'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Json } from '../../components'

const FeatureBadge = ({ enabled }: { enabled?: boolean | any }) => {
  if (enabled === true) {
    return <Badge color='success'>Enabled</Badge>
  }
  if (enabled === false) {
    return <Badge color='neutral'>Disabled</Badge>
  }
  if (!enabled) {
    return <Badge color='gray'>{'Not set'}</Badge>
  }
  return <Json json={enabled} />
}

export default FeatureBadge
