import * as React from 'react'
import { Link, useMatch } from 'react-router-dom'
import cx from 'classnames'

type HeadingProps = { title: React.ReactNode }

const Heading = ({ title }: HeadingProps) => {
  return (
    <div
      className={cx(
        'py-2 text-default outline-none focus-visible:shadow-focus my-0.5',
        'w-full text-left',
        'pr-1 rounded flex items-center',
        'font-semibold',
        'pl-8'
      )}
    >
      {title}
    </div>
  )
}

type ItemProps = {
  title?: string
  to: string
}

const Item = (props: ItemProps) => {
  const { title, to } = props

  const isActive = !!useMatch({ path: to + '/*' })

  return (
    <Link
      className={cx(
        'py-2 text-default outline-none no-underline focus-visible:shadow-focus my-0.5',
        'w-full text-left',
        'pr-1 rounded flex items-center',
        'pl-11',
        !isActive && 'hover:bg-darken-4',
        isActive && 'hover:bg-brand-10 bg-brand-0 font-semibold'
      )}
      to={to}
    >
      {title || to}
    </Link>
  )
}

const Divider = () => {
  return (
    <div className='py-2'>
      <div style={{ borderBottom: '1px solid #ccc' }} />
    </div>
  )
}

type NavProps = {
  children: React.ReactNode
} & React.ComponentProps<'div'>

const Nav = ({ children, ...rest }: NavProps) => {
  return <div {...rest}>{children}</div>
}

Nav.Heading = Heading
Nav.Item = Item
Nav.Divider = Divider

export default Nav
