import * as React from 'react'

import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

import AppLayout from './layout/AppLayout'

import {
  FeatureFlagsPage,
  ToastGlobalPage,
  EcBanquetPropsPage,
  BanquetPropsPage,
  LocalStoragePage,
  SessionStoragePage,
  ExperimentsPage,
  AccessControl,
  JobsExportConverter
} from '../pages'

const router = createBrowserRouter([
  {
    path: 'staff-tools',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Navigate to='feature-flags' replace />
      },
      {
        path: 'feature-flags',
        element: <FeatureFlagsPage />
      },
      {
        path: 'jobs-export-converter',
        element: <JobsExportConverter />
      },

      {
        path: 'toast-global',
        element: <ToastGlobalPage />
      },
      {
        path: 'ec-banquet-props',
        element: <EcBanquetPropsPage />
      },
      {
        path: 'banquet-props',
        element: <BanquetPropsPage />
      },
      {
        path: 'local-storage',
        element: <LocalStoragePage />
      },
      {
        path: 'session-storage',
        element: <SessionStoragePage />
      },
      {
        path: 'experiments/*',
        element: <ExperimentsPage />
      },
      {
        path: 'access-control',
        element: <AccessControl />
      },
      {
        path: '*',
        element: <Navigate to='/staff-tools' />
      }
    ]
  }
])

const AppRouter = () => {
  return <RouterProvider router={router} />
}

export default AppRouter
