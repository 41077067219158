import * as React from 'react'
import AppProviders from './AppProviders'

import AppRouter from './AppRouter'

const StaffToolsApp = (props?: any) => {
  const isInternalUser = props?.ec?.user?.userType === 'toast_internal'

  if (isInternalUser) {
    return (
      <AppProviders>
        <AppRouter />
      </AppProviders>
    )
  }

  return <div>{`userType=${props?.user?.userType}`}</div>
}

export default StaffToolsApp
