import * as React from 'react'
import { TitleBar, ObjectCards } from '../../components'

const ToastGlobalPage = () => {
  // @ts-ignore
  const toastGlobal = { ...window.Toast }

  return (
    <>
      <TitleBar title='Toast Global' />

      <ObjectCards object={toastGlobal} />
    </>
  )
}

export default ToastGlobalPage
