import * as React from 'react'
import { useState } from 'react'
import map from 'lodash/map'
import toPairs from 'lodash/toPairs'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import Searchbox from '../Searchbox'
import { stringMatches } from '../../helpers'
import Section from './Section'

type Props = {
  object: Record<string, any>
}

const ObjectCards = ({ object }: Props) => {
  const pairs = toPairs(object)

  const sortedPairs = sortBy(pairs, ([key]) => key)

  const [search, setSearch] = useState<string>('')

  const filteredParis = filter(sortedPairs, ([key]) => {
    return stringMatches(key, search)
  })

  return (
    <>
      <Searchbox
        value={search}
        onChange={setSearch}
        placeholder='search keys'
      />
      <div className='pb-8' />

      {map(filteredParis, ([key, item]) => {
        return <Section key={key} title={key} value={item} />
      })}
    </>
  )
}

export default ObjectCards
