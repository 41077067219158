import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { Portal } from '@toasttab/ec-layout'
import StaffNavigation from './StaffNavigation'

const AppLayout = () => {
  return (
    <>
      <Portal id='page-sidebar' component={<StaffNavigation />} />
      <Outlet />
    </>
  )
}

export default AppLayout
