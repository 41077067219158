import * as React from 'react'

import Nav from './NavItems'

const StaffNavigation = () => {
  return (
    <Nav style={{ maxWidth: '300px', minWidth: '200px' }}>
      <Nav.Heading title='Staff Tools' />

      <Nav.Item title='Feature Flags' to='/staff-tools/feature-flags' />

      <Nav.Divider />

      <Nav.Heading title='Ops Tools' />

      <Nav.Item
        title='Job Export Converter'
        to='/staff-tools/jobs-export-converter'
      />

      <Nav.Divider />

      <Nav.Heading title='FE Dev' />

      <Nav.Item title='Toast Global' to='/staff-tools/toast-global' />
      <Nav.Item title='EC Banquet Props' to='/staff-tools/ec-banquet-props' />
      <Nav.Item title='Banquet Props' to='/staff-tools/banquet-props' />
      <Nav.Item title='Local Storage' to='/staff-tools/local-storage' />
      <Nav.Item title='Session Storage' to='/staff-tools/session-storage' />

      <Nav.Heading title='Access Control' />
      <Nav.Item title='Manage' to='/staff-tools/access-control' />

      <Nav.Heading title='Experiments' />

      <Nav.Item
        title='Data Processing'
        to='/staff-tools/experiments/data-processing'
      />
    </Nav>
  )
}

export default StaffNavigation
