import * as React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import Transformer from '../Transformer'
import _ from 'lodash'

// call addtr('companyManagement', 'Company Management', 'Gestión de Empresa');

const splitTrim = (str: string, token: string) => str.split(token).map(_.trim)

const parseTranslations = (str: string) => {
  const lines = str.split('\n').map(_.trim)

  const listOfLists = _.map(lines, (line) => {
    const cleaned = line.replace('call addtr(', '').replace(');', '')
    return splitTrim(cleaned, ', ').map((str) => str.slice(1).slice(0, -1))
  })

  const en: Record<string, string> = {}
  const es: Record<string, string> = {}

  _.each(listOfLists, (list) => {
    const [key, english, spanish] = list
    en[key] = english
    es[key] = spanish
  })

  return JSON.stringify({ en, es }, null, 3)
}

const Translations = () => {
  return (
    <CardContainer>
      <h3>Translations</h3>

      <Transformer transformer={parseTranslations} />
    </CardContainer>
  )
}

export default Translations
