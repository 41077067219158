import * as React from 'react'

type Props = React.PropsWithChildren<{}>

class ErrorBoundary extends React.Component<Props> {
  state: {
    hasError: boolean
    error: unknown
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: undefined
    }
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error }
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }

    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
