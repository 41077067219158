import * as React from 'react'
import { Suspense } from 'react'
import { ErrorBoundary } from '../components'
import { PortalProvider } from '@toasttab/ec-layout'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

type Props = React.PropsWithChildren<{}>

const TeamAppProviders = ({ children }: Props) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <PortalProvider value='data-ec-staff-tools'>
            {children}
          </PortalProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export default TeamAppProviders
