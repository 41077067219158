import * as React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import Transformer from '../Transformer'
import _ from 'lodash'
import Papa from 'papaparse'

const splitTrim = (str: string, token: string) => str.split(token).map(_.trim)

const parseJobsAndRates = (str: string) => {
  const result = Papa.parse(str)
  const json = result.data

  const [, ...list] = json

  const output: any[][] = [
    ['Last Name', 'First Name', 'Job Description', 'Wage']
  ]

  _.each(list, (row: any) => {
    const [, , lastName, firstName, , , , , jobDescriptionStr, wageStr] = row

    const jobDescriptions = splitTrim(jobDescriptionStr, ';')

    const wages = splitTrim(wageStr, ';')

    _.each(jobDescriptions, (jobDescription, index) => {
      output.push([lastName, firstName, jobDescription, wages[index]])
    })
  })

  return Papa.unparse(output)
}

const ExportImport = () => {
  return (
    <CardContainer>
      <h3>Export/Import</h3>

      <Transformer transformer={parseJobsAndRates} />
    </CardContainer>
  )
}

export default ExportImport
