import { useEcBanquetProps } from '@toasttab/ec-banquet-props'
import type { LDClient } from 'launchdarkly-js-client-sdk'

interface SuspenseState {
  result: LDClient | undefined
  isFailed: boolean
}

const state: SuspenseState = {
  result: undefined,
  isFailed: false
}

function useLdClient(): LDClient | undefined {
  const {
    ec: { launchDarklyClientPromise }
  } = useEcBanquetProps()

  if (state.isFailed) return undefined
  if (state.result) return state.result

  launchDarklyClientPromise
    .then((result) => (state.result = result))
    .catch(() => (state.isFailed = true))

  throw launchDarklyClientPromise
}

export default useLdClient
