import * as React from 'react'
import { useMemo } from 'react'
import { TitleBar, ObjectCards } from '../../components'
import { useBanquetProps } from 'banquet-runtime-modules'

import jwtDecode from 'jwt-decode'

const BanquetPropsPage = () => {
  const props = useBanquetProps()

  // @ts-ignore
  const { token, ec, ...rest } = props

  const decodedToken = useMemo(() => {
    try {
      return jwtDecode(token)
    } catch {
      return ''
    }
  }, [token])

  const value = { decodedToken, ...rest }

  return (
    <>
      <TitleBar title='Banquet Props' />

      <ObjectCards object={value} />
    </>
  )
}

export default BanquetPropsPage
