import * as React from 'react'
import { useMemo, useState } from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { TitleBar, TextArea } from '../../components'
import { Button } from '@toasttab/buffet-pui-buttons'
import _ from 'lodash'
import Papa from 'papaparse'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

const splitTrim = (str: string, token: string) => {
  if (!str) return []
  if (!token) return [str.trim()]
  return _.compact(str.split(token).map(_.trim))
}

const downloadCSV = (str: string, name: string) => {
  const csvData = new Blob([str], { type: 'text/csv;charset=utf-8;' })
  const url = window.URL.createObjectURL(csvData)
  var tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', name || 'output.csv')
  tempLink.click()
}

const COLS = ['Location', 'Last Name', 'First Name', 'Job Description', 'Wage']

const parseJobsAndRates = (str: string) => {
  const result = Papa.parse(str)
  const json = result.data
  const [, ...list] = json

  let emptyCount = 0

  const outputData: any[][] = [COLS]

  _.each(list, (row: any) => {
    const [location, , lastName, firstName, , , , , jobStr, wageStr] = row

    const jobs = splitTrim(jobStr, ';')
    const wages = splitTrim(wageStr, ';')

    if (_.isEmpty(jobs) || (_.size(jobs) === 1 && _.first(jobs) === `''`)) {
      emptyCount = emptyCount + 1
    }

    _.each(jobs, (job, index) => {
      const wage = wages[index]
      const payrollLine = [location, lastName, firstName, job, wage]
      outputData.push(payrollLine.map(_.trim))
    })
  })

  const output = Papa.unparse(outputData)
  const payrollCount = _.size(outputData) - 1

  return { output, posCount: _.size(list), emptyCount, payrollCount }
}

const JobsExportConverter = () => {
  const [source, setSource] = useState<string>('')

  const result = useMemo(() => {
    try {
      return parseJobsAndRates(source)
    } catch (e) {
      return { output: `${e}`, posCount: 0, emptyCount: 0, payrollCount: 0 }
    }
  }, [source])

  const { output, posCount, emptyCount, payrollCount } = result

  const name = `${payrollCount}-payroll-jobs.csv`

  const onDownload = () => downloadCSV(output, name)

  const subtitle = (
    <>
      {`In Memory of `}
      <Tooltip>
        <Tooltip.Trigger className='inline-block'>
          <span className='cursor-default'>{`Toaster Ian Margolin`}</span>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <div className='w-100'>
            Toasters Ian Margolin (Onboarding) and Will Lubelski (Engineering)
            met over a #donut Zoom call in 2022, and kept up each month.
            <div className='h-4' />
            Bonding over stories of the fun and not-so-fun parts of their jobs,
            they concocted and built this little tool to automate the
            particularly manual task of converting jobs data.
            <div className='h-4' />
            Tragically Ian passed away in July 2024, but if you're using this
            page, then you are still benefiting from his work, so consider using
            a bit of the time he's still saving you to get to know a new Toaster
            on another team.
            <div className='h-4' />
            You never know who you'll meet or what it will lead to.
          </div>
        </Tooltip.Content>
      </Tooltip>
    </>
  )

  return (
    <>
      <CardContainer>
        <TitleBar title={`Jobs Export Converter`} subtitle={subtitle} />
        <div className='text-disabled p-1'>
          - Copying directly from excel often copies formatting rather than raw
          values. opening an exported CSV file in notepad or another simple text
          editor may do better at getting the raw data
        </div>
        <div className='text-disabled p-1'>
          - Paste the output CSV from POS (including the header row) into the
          top box and if all goes well, below will be the csv contents to be
          imported into payroll.
        </div>
        <div className='h-4' />
        <TextArea value={source} onChange={setSource} placeholder='Source' />
        <div className='h-12' />
        <div className='text-disabled p-1'>- POS Job Count: {posCount}</div>
        <div className='text-disabled p-1'>
          - Empty POS Job Description Count: {emptyCount}
        </div>
        <div className='text-disabled p-1'>
          - Payroll Job Count: {payrollCount}
        </div>
        <div className='h-4' />
        <TextArea value={output} placeholder='Output' />

        <Button variant='secondary' onClick={onDownload}>
          {name}
        </Button>
      </CardContainer>
    </>
  )
}

export default JobsExportConverter
