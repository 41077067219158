import * as React from 'react'
import { useMemo } from 'react'

const Json = ({ json }: { json: any }) => {
  const str = useMemo(() => {
    try {
      return JSON.stringify(json, null, 3)
    } catch {
      return 'JSON Error'
    }
  }, [json])

  return <div className='whitespace-pre-wrap font-mono break-words'>{str}</div>
}

export default Json
