import * as React from 'react'

type Props = {
  value: string
  onChange?: (str: string) => void
  placeholder?: string
}

const TextArea = (props: Props) => {
  const { value, onChange, placeholder } = props

  return (
    <textarea
      className='border w-full h-96 font-mono p-2 resize-none'
      value={value}
      onChange={(evt) => {
        if (onChange) {
          onChange(evt.target.value)
        }
      }}
      placeholder={placeholder}
    />
  )
}

export default TextArea
